import React, { Component } from 'react'
import CollectionDesignerBlock from 'components/collectionDesignerBlock'
import ProductInquiryForm from 'components/forms/productInquiry'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import CollectionProductsSlideshow from 'components/slideshows/collectionProductsSlideshow'
import FullWidthSlideshow from 'components/slideshows/fullWidthSlideshow'
import ProductImagesSlideshow from 'components/slideshows/productImagesSlideshow'
import RelatedProjectsSlideshow from 'components/slideshows/relatedProjectsSlideshow'
import DownloadOverlayGrid from 'components/downloadOverlayGrid'
import LoginOverlay from 'components/loginOverlay'
import Title from 'components/title'
import { ReactSVG } from 'react-svg'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadOverlayShow: false,
      productInquiryOverlayShow: false,
      loginOverlayShow: false,
      userLocationApproved: false,
    }
    this.toggleState = this.toggleState.bind(this)
    this.showDownloadOverlay = this.showDownloadOverlay.bind(this)
    this.showLoginOverlay = this.showLoginOverlay.bind(this)
    this.hideLoginOverlay = this.hideLoginOverlay.bind(this)
    this.checkLogin = this.checkLogin.bind(this)
  }

  toggleState(e) {
    const state = e.currentTarget.getAttribute('data-state')
    this.setState((prevState) => {
      return {
        [state]: !prevState[state],
      }
    })
    document.querySelector('#header').classList.toggle('show')
  }

  showDownloadOverlay() {
    this.setState({ downloadOverlayShow: true })
    document.querySelector('#header').classList.toggle('show')
  }

  showLoginOverlay() {
    this.setState({ loginOverlayShow: true })
  }

  hideLoginOverlay() {
    this.setState({ loginOverlayShow: false })
  }

  checkLogin() {
    let loginUser = {}
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.loginUser) {
        loginUser = JSON.parse(sessionStorage.loginUser)
      }
    }
    let pass = false
    const getApproval = sessionStorage.getItem('userLocationApproved')
    if (getApproval && getApproval === 'yes') pass = true
    if (sessionStorage.getItem('loginUser') && loginUser.approved) pass = true
    if (pass) {
      this.showDownloadOverlay()
    } else {
      this.showLoginOverlay()
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const product = this.props.data.productQuery
    return (
      <Layout>
        <div id="content" className="single-product">
          <input type="hidden" name="productLoginError" value="_loginError" />
          <Title
            title={product.title}
            subtitle={product.collection.title}
            subtitleLink={`/collections/collections/${product.collection.slug}`}
          />
          <section className="page-content">
            <div className="wrap">
              <div className="product-info">
                <div className="col">
                  <div>
                    <ProductImagesSlideshow images={product.imagesProduct} />
                  </div>
                </div>
                <div className="col">
                  <div className="txt">
                    {product.content && (
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: product.content.childMarkdownRemark.html,
                        }}
                      />
                    )}
                    {product.downloads && (
                      <div className="download-files">
                        <a className="ui" onClick={this.checkLogin}>
                          <span className="icon-download" />
                          {tr('PRODUCT_DOWNLOADS')}
                        </a>
                      </div>
                    )}
                    <div className="wrap-button-cart">
                      <a
                        className="ui button inquire"
                        onClick={this.toggleState}
                        data-state="productInquiryOverlayShow"
                      >
                        {tr('ENQUIRE')}
                      </a>
                    </div>
                    {product.combinations && (
                      <ProductCombinations product={product} tr={tr} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div data-field="slideshow">
              {product.slides && <FullWidthSlideshow slides={product.slides} />}
            </div>

            {product.collection.projects && (
              <RelatedProjectsSlideshow
                projects={product.collection.projects}
                sourcePage="product"
                tr={tr}
              />
            )}

            <CollectionDesignerBlock collection={product.collection} tr={tr} />

            {product.collection.products && (
              <CollectionProductsSlideshow
                products={product.collection.products}
                thisProduct={product}
              />
            )}
          </section>

          {product.downloads && (
            <>
              <DownloadOverlayGrid
                downloadOverlayShow={this.state.downloadOverlayShow}
                toggleState={this.toggleState}
                products={[product]}
                sourcePage="product"
                title={product.title}
                tr={tr}
              />
              <LoginOverlay
                loginOverlayShow={this.state.loginOverlayShow}
                hideLoginOverlay={this.hideLoginOverlay}
                tr={tr}
              />
            </>
          )}

          <ProductInquiryOverlay
            productInquiryOverlayShow={this.state.productInquiryOverlayShow}
            toggleState={this.toggleState}
            product={product}
            tr={tr}
            data={this.props.data}
          />
        </div>
      </Layout>
    )
  }
}

const ProductInquiryOverlay = ({
  productInquiryOverlayShow,
  toggleState,
  product,
  tr,
  data,
}) => {
  const showClass = productInquiryOverlayShow ? 'show' : ''
  return (
    <div id="inquiry-overlay" className={`overlay-top ${showClass}`}>
      <div className="overlay-content">
        <div className="bg" />
        <div className="window-inquire">
          <a
            className="ui close-icon"
            onClick={toggleState}
            data-state="productInquiryOverlayShow"
          >
            <ReactSVG src="/images/icon-close.svg" />
          </a>
          <div className="window-content">
            <section>
              <ul className="product-specs">
                <li data-field="product-image">
                  <ImageWrap image={product.imageThumb} />
                </li>
                <li>
                  <div className="small-caps">{tr('ENQUIRE')}</div>
                  <div className="title" data-field="product-title">
                    {product.title}
                  </div>
                </li>
              </ul>
              <ProductInquiryForm product={product} data={data} />
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

class ProductCombinations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMat1id: null,
      activeMat2id: null,
    }
    this.setActiveMat1id = this.setActiveMat1id.bind(this)
    this.setActiveMat2id = this.setActiveMat2id.bind(this)
  }
  setActiveMat1id(e) {
    const getMat1 = e.currentTarget.getAttribute('data-mat1')
    this.setState((prevState) => {
      if (prevState.activeMat1id === getMat1) {
        return { activeMat1id: null }
      } else {
        return { activeMat1id: getMat1 }
      }
    })
  }
  setActiveMat2id(e) {
    const getMat2 = e.currentTarget.getAttribute('data-mat2')
    this.setState({ activeMat2id: getMat2 })
  }
  render() {
    const tr = this.props.tr
    const product = this.props.product

    // make a new array with mat1 > mat2
    let mat1 = []
    product.combinations.forEach((combo) => {
      const match = mat1.filter(
        (thisMat1) => thisMat1.id === combo.material1.id,
      ) // check if material is already in array
      if (match.length === 0) {
        mat1.push(combo.material1)
      }
    })

    mat1.forEach((thisMat1) => {
      const matches = product.combinations.filter(
        (thisCombo) => thisCombo.material1.id === thisMat1.id,
      ) // different match: find all combinations where mat1 id = this mat1's id
      if (matches.length > 0) {
        thisMat1.mat2 = []
        matches.forEach((match) => {
          thisMat1.mat2.push(match.material2)
        })
      }
    })
    return (
      <div>
        <div className="product-combos">
          <div className="title-top small-caps max-12">
            {tr('SUGGESTED_MATERIALS')}
          </div>

          {this.state.activeMat1id && this.state.activeMat2id && (
            <div className="thumbs-holder">
              {product.combinations.map((combo, i) => {
                return (
                  <div
                    key={i}
                    className={
                      'inner' +
                      (combo.material1.id === this.state.activeMat1id &&
                      combo.material2.id === this.state.activeMat2id
                        ? ' active'
                        : '') +
                      (i === 0 ? ' placeholder' : '')
                    }
                  >
                    <ImageWrap key={i} image={combo.image} />
                  </div>
                )
              })}
            </div>
          )}

          <div className="inner">
            {mat1.map((thisMat1, i) => {
              return (
                <div
                  key={i}
                  className={
                    'mat1-section ' +
                    (thisMat1.id === this.state.activeMat1id ? 'active' : '')
                  }
                >
                  <a
                    className="ui mat1-item"
                    data-mat1={thisMat1.id}
                    onClick={this.setActiveMat1id}
                  >
                    <div className="swatches">
                      <ImageWrap image={thisMat1.image} aspectRatio={1} />
                    </div>
                    <p>{thisMat1.title}</p>
                    <div className="trigger" />
                  </a>
                  <div className="mat2-items">
                    {thisMat1.mat2.map((thisMat2, j) => {
                      return (
                        <a
                          key={j}
                          className={
                            'ui mat2-item ' +
                            (thisMat1.id === this.state.activeMat1id &&
                            thisMat2.id === this.state.activeMat2id
                              ? 'active'
                              : '')
                          }
                          data-mat2={thisMat2.id}
                          onClick={this.setActiveMat2id}
                        >
                          <div className="swatches">
                            <ImageWrap image={thisMat1.image} aspectRatio={1} />
                            <ImageWrap image={thisMat2.image} aspectRatio={1} />
                          </div>
                          <p>
                            {thisMat1.title} / {thisMat2.title}
                          </p>
                        </a>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="link-bottom small-caps max-12">
            <LinkWrap to="/collections/materials" style={{ color: 'black' }}>
              + {tr('SEE_MATERIAL_LIBRARY')}
            </LinkWrap>
          </div>
        </div>
      </div>
    )
  }
}

export default Product

export const pageQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    productQuery: contentfulProducts(slug: { eq: $slug }) {
      id
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      collection {
        id
        title
        slug
        content {
          childMarkdownRemark {
            html
          }
        }
        designer {
          id
          image {
            ...GatsbyImage
          }
          title
          slug
        }
        products {
          id
          datePosted
          title
          slug
          imageThumb {
            ...GatsbyImage
          }
          collection {
            id
            order
          }
          categories {
            id
            order
          }
        }
        projects {
          title
          slug
          isFeatured
          datePosted
          location {
            id
            title
          }
          image {
            ...GatsbyImage
          }
        }
      }
      imageThumb {
        ...GatsbyImage
      }
      imagesProduct {
        ...GatsbyImage
      }
      slides {
        id
        images {
          title
          ...GatsbyImage
        }
        caption
        highlightPoints {
          title
          caption
          triggerPositionLeft
          triggerPositionTop
          popupAlign
        }
      }
      downloads {
        file {
          url
          fileName
          details {
            size
          }
          contentType
        }
        description
        ...GatsbyImage
      }
    }
  }
`

// combinations {
//   id
//   label
//   material1 {
//     id
//     title
//     image {
//       ...GatsbyImage
//     }
//   }
//   material2 {
//     id
//     title
//     image {
//       ...GatsbyImage
//     }
//   }
//   image {
//     ...GatsbyImage
//   }
// }
