import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { trFunction } from 'utils/functions'

class ProductInquiryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const product = this.props.product

    const sampleData = {
      fullName: 'fullName',
      company: 'company',
      email: 'email',
      phone: 'phone',
      country: 'country',
      postcode: 'postcode',
      comments: 'comments',
      productName: 'productName',
    }

    const onSubmit = (values) => {
      this.setState({ error: null, postedEmail: null, loading: true })
      const postObj = { form: values, locale, translations }

      // const url = 'http://localhost/stellar-js/email/emails/product/email.php';
      const url =
        'https://stellar.lanternbox.com/email/emails/product/email.php'

      const that = this
      axios
        .post(url, postObj)
        .then(function (response) {
          setTimeout(
            () => that.setState({ postedEmail: true, loading: false }),
            500,
          )
        })
        .catch(function (error) {})
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ productName: product.title }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="layout-2">
              <div>
                <Field
                  name="fullName"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_FULL_NAME')}*`}
                  required
                />
              </div>
              <div>
                <Field
                  name="company"
                  component="input"
                  type="text"
                  placeholder={tr('FORM_ITEM_COMPANY')}
                />
              </div>
            </div>
            <div className="layout-2">
              <div>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder={`${tr('FORM_ITEM_EMAIL')}*`}
                  required
                />
              </div>
              <div>
                <Field
                  name="phone"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_PHONE')}*`}
                  required
                />
              </div>
            </div>
            <div className="layout-2">
              <div>
                <Field
                  name="country"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_COUNTRY')}*`}
                  required
                />
              </div>
              <div>
                <Field
                  name="postcode"
                  component="input"
                  type="text"
                  placeholder={`${tr('FORM_ITEM_POSTCODE')}*`}
                  required
                />
              </div>
            </div>
            <p>
              <Field
                name="comments"
                component="textarea"
                placeholder={tr('FORM_ITEM_COMMENTS')}
              />
            </p>
            <Field
              name="productName"
              component="input"
              type="text"
              style={{ display: 'none' }}
            />

            <div className="footer">
              <div className="layout-2">
                <div>*{tr('FORM_ITEM_REQUIRED_FIELDS')}</div>
                <p
                  className={`thankyou ${this.state.postedEmail ? 'show' : ''}`}
                  style={{ float: 'left' }}
                >
                  {tr('FORM_PRODUCT_INQUIRY_SUCCESS')}
                </p>
                <div
                  className={`submit-wrap ${loadingClass}`}
                  style={{ width: 'auto', clear: 'none' }}
                >
                  <span className="loader">
                    <ReactSVG src="/images/loader.svg" />
                  </span>
                  <input
                    type="submit"
                    value={tr('FORM_ITEM_SUBMIT')}
                    disabled={this.state.loading}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    )
  }
}

export default ProductInquiryForm
