import React from 'react'
import Slider from 'react-slick'
import ImageWrap from 'components/ImageWrap'
import NextArrow from './nextArrow'
import PrevArrow from './prevArrow'

const Slide = ({ image }) => {
  return <ImageWrap image={image} />
}

const ProductImagesSlideshow = ({ images }) => {
  const settings = {
    arrows: true,
    centerMode: false,
    dots: false,
    fade: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 777,
    variableWidth: false,
    focusOnSelect: false,
  }

  return (
    <div className="mixed-slideshow-container half has-arrows">
      <Slider {...settings} className="mixed-slideshow">
        {images.map((image, i) => (
          <Slide key={i} image={image} />
        ))}
      </Slider>
      {/* // arrows */}
    </div>
  )
}
export default ProductImagesSlideshow
